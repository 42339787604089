import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ArticlePosts from "./ArticlePosts";
import ArticleForm from "./ArticleForm";
import FeaturedCategories from "./FeaturedCategories";
import Layout from "../components/Layout/layout";
import SwitchBlog from "./SwitchBlog";
import "./Blog.scss";

interface Tag {
  pageContext;
  props;
  title;
  path: string;
}

const BlogTags: React.FC<Tag> = (props) => {
  const { pageContext } = props;
  const data = useStaticQuery(graphql`
    {
      allStrapiPost {
        totalCount
        nodes {
          slug
          date
          Title
          id
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          blog_tags {
            tagName
            tagLink
            id
          }
          blogPara
          ReadTime
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 720)
              }
            }
          }
        }
      }
    }
  `);

  const posts = data.allStrapiPost.nodes;
  const filteredPosts = posts.filter((post) => {
    const tag = post.blog_tags.filter((item) => {
      return item.tagName === pageContext.title;
    });

    return Boolean(tag.length);
  });

  return (
    <Layout>
      <div className="review py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="page_header pb-5">
                <div className="admin_page">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="d-flex admin_name">
                          <div>
                            <h1>{pageContext.title}</h1>
                            <div className="archive_count">
                              {filteredPosts.length} {pageContext.posts}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <>
                {filteredPosts.map((e) => (
                  <SwitchBlog
                    slug={e.slug}
                    image={e.image}
                    category={e.blog_categories}
                    title={e.Title}
                    blogPara={e.blogPara}
                    date={new Date(e.date)
                      .toDateString()
                      .split(" ")
                      .slice(1, 4)
                      .toString()
                      .replace(",", " ")}
                    adminName={e.admin_users[0].username}
                    adminLink={e.admin_users[0].username?.toLowerCase().replace(" ", "-")}
                    readTime={e.ReadTime}
                  />
                ))}
              </>
            </div>

            <div className="col-lg-4 px-lg-5 mt-3">
              <ArticleForm />
              <div className="pt-3">
                <ArticlePosts />
              </div>
              <div className="pt-3">
                <FeaturedCategories />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default React.memo(BlogTags);